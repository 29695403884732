<template>
  <div>

		<div v-if="activeTab === 1" class="tabcontent hidden-wizard-container">
			<RoutePlanForm :plan="planToSave" @successSave="success" @cancel="cancel"/>
		</div>

		<div v-if="activeTab === 2" class="route-plans-con">
			<a v-on:click="openAddNewPlanForm" v-bind:class="[ activeTab === 1 ? 'active' : '' ]" class="btn mt-3 btn-primary tab-link-register">
				<font-awesome-icon icon="plus"/>
				{{ $t('routePlan.addNewPlan') }}
			</a>
			<div class="route-plans w-100">
				<route-plan-card :route-plan="rp" v-for="rp in routePlans"  :key="rp.id" @update="loadData" @copy="copy" @remove="removePlan" />
				<Skeleton v-if="loading" :count="4" width="100%"  class="skeletons" height="103px" />
				<ServerError v-if="error && !loading" @retry="loadData()" />
				<div id="masonry" v-if="!loading && !error"></div>
			</div>
			<div v-if="routePlans.length === 0 && !loading">
				<h5>{{ $t('routePlan.emptyList') }}</h5>
			</div>

		</div>
  </div>
</template>

<script>

import RoutePlanForm from "@/components/routePlan/RoutePlanForm";
import {HELPER_FUNCTIONS, LOCAL_STORAGE_SERVICE, STORAGE_KEY} from "@/plugins/utils";
import ShareButton from "@/components/sharing/ShareButton.vue";
import ServerError from "@/components/ServerError.vue";
import RouteCard from "@/components/route/RouteCard.vue";
import RoutePlanCard from "@/components/routePlan/RoutePlanCard.vue";
import { Skeleton } from "vue-loading-skeleton";


const PRE_UID = 'rp_';
export default {
  name: "RoutePlanning",
  components: { RoutePlanCard, ServerError, RoutePlanForm, Skeleton},
  data() {
    return {
      user: null,
      routePlans: [],
      selectedPlan: null,
      activeTab: 2,
      isEdit: false,
      planToSave:null,
			loading: true,
			error: false,
    };
  },
  created() {
  },
  mounted() {
    this.loadData();
    this.loadUser();
    this.planToSave =  this.createPlaneToSave();
  },
  methods: {
    createPlaneToSave() {
      return {
        name: '',
        defaultSpeed: 40,
        avgConsumption: 0,
				/** @type {SpeedUnitType} */
        speedUnit: this.$units.speed.kmh.key,
				/** @type {ConsumptionUnitType} */
        avgConsumptionUnit: this.$units.consumption.lh.key,
				/** @type {DistanceUnitType} */
        distanceUnit: this.$units.distance.nmi.key,
				/** @type {TimeUnitType} */
        flightTimeUnit: this.$units.time.h.key,
        planeRegistrationNumber: '',
        magneticCorrection: -5,
        dateOfScheduledFlight: new Date(),
        routePlanElements: [],
        startElementTitle: "Nem ismert",
        pilotInCommand: (this.user ? this.user.name : ""),
        passenger: ""
      };
    },
    async loadUser() {
			const user = await this.$rest.getLoggedInUser();
			if(!user) return;
			this.user = user;
			this.planToSave = this.createPlaneToSave();
    },
    async loadData() {
			this.loading = true;
      this.back();
      this.routePlans = [];

      const storedRoutePlans = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.ROUTE_PLANE, {});
			this.routePlans = ((await this.$rest.getRoutePlansForUser()) || []).map(routePlan => {
				routePlan.stored = false;
				const storedKey = Object.keys(storedRoutePlans).find(uid => uid === PRE_UID + routePlan.id);
				if (storedKey) {
					routePlan.stored = true;
				}
				return routePlan;
			});

			this.loading = false;
    },

    openAddNewPlanForm() {
      this.selectedPlan = null;
      this.activeTab = 1;
    },
		/** @param {RoutePlan} plan */
    edit(plan) {
      this.$router.push({path: '/route-planning-element/' + plan.id});
    },
    back() {
      this.selectedPlan = null;
      this.activeTab = 2;
      this.isEdit = false;
      this.$forceUpdate();
    },
    cancel() {
      this.back();
      this.planToSave = this.createPlaneToSave();
    },
		/** @param {RoutePlan} plan */
    show(plan) {
      this.$router.push({path: '/route-planning-element/' + plan.id});
    },
		/** @param {RoutePlan} plan */
    modify(plan) {
      this.selectedPlan = null;
      this.activeTab = 1
      this.planToSave = plan;
    },
		/** @param {RoutePlan} plan */
    copy(plan) {
      const copy = {...plan};
      copy.id = undefined;
      copy.routePlanElements.forEach(element => {
        element.id = undefined;
      })
      this.planToSave = copy;
      this.activeTab = 1;
      this.selectedPlan = null;
    },
		/** @param {RoutePlan} plan */
    success(plan) {
      plan.dateOfScheduledFlight = HELPER_FUNCTIONS.frontEndDateFormat(plan.dateOfScheduledFlight);
      let existingPlan = this.routePlans.find(p => p.id === plan.id);
      if (existingPlan !== undefined) {
        this.show(plan);
      } else {
        this.routePlans.push(plan);
        this.edit(plan)
      }
      this.planToSave = this.createPlaneToSave();
      this.activeTab = 2;
    },
		/** @param {RoutePlan} plan */
    async removePlan(plan) {
      if (!await this.$confirm(this.$t('are-u-sure'))) {
				return;
			}
			await this.$rest.deleteRoutePlan(plan.id);
			const index = this.routePlans.map(p => p.id).indexOf(plan.id);
			this.routePlans.splice(index, 1);
			this.removeFromStore(plan);
    },
		/** @param {RoutePlan} plan */
    saveToStore(plan) {
      if (plan.routePlanElements.length < 1) {
        alert(this.$t('routePlan.plan-emptry'));
        return
      }
      plan.stored = true;
      const storedRoutePlans = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.ROUTE_PLANE, {});
      storedRoutePlans['rp_' + plan.id] = plan;
      LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.ROUTE_PLANE, storedRoutePlans);
      let existingPlan = this.routePlans.find(p => p.id === plan.id);
      if (existingPlan !== undefined) {
        existingPlan.stored = true;
      }
    },
		/** @param {RoutePlan} plan */
    removeFromStore(plan) {
      plan.stored = false;
      const storedRoutePlans = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.ROUTE_PLANE, {});
      delete storedRoutePlans[PRE_UID + plan.id];
      LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.ROUTE_PLANE, storedRoutePlans);
    },
    removeAllFromStore() {
      LOCAL_STORAGE_SERVICE.removeItem(STORAGE_KEY.ROUTE_PLANE)
    },
  }
}
</script>

<style scoped>
.btn-action {
  font-size: 12px !important;
  font-weight: normal !important;
}


.route-plans-con {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 1024px;
	margin: 0 auto;
}
.route-plans-con .route-plans {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.2rem;
	margin-top: 2rem;
	padding: 1rem;
	width: 100%;
}


@media screen and (max-width: 500px){
	.route-plans-con .route-plans {
		flex-direction: column;
	}
}

.skeletons {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	max-width: 1248px;
}

</style>

<style>
	.skeletons .pu-skeleton {
		width: 100%;
		border-radius: 1rem;
	}

	@media screen and (max-width: 800px) {
		.skeletons {
			align-items: center;
		}
		.skeletons .pu-skeleton {
			height: 246px !important;
			max-width: 406px;
		}
	}
</style>
