<template>
  <RoutePlanning />
</template>

<script>
import RoutePlanning from "@/components/routePlan/RoutePlanning";

export default {
  name: "RoutePlanningView",
  components: {RoutePlanning},
  comments:{RoutePlanning}
}
</script>

<style scoped>

</style>
