<template>
  <div class="form-group">
    <div class="row preview">
      <div class="col-12 form-group">
        <label for="rpName">{{ $t('routePlan.name') }}: *</label>
        <input id="rpName" type="text" v-model="plan.name" required class="form-control"/>

      </div>

      <!--      <div class="col-sm-6 form-group">-->
      <!--        <label for="dateOfScheduledFlight">{{ $t('routePlan.dateOfScheduledFlight') }}: *</label>-->
      <!--        <input id="dateOfScheduledFlight" type="date" v-model="plan.dateOfScheduledFlight" required-->
      <!--               class="form-control"/>-->
      <!--      </div>-->

    </div>

    <div class="row preview mt-3">
      <div class="col-md-6">
        <label>{{ $t('routePlan.selectPlane') }}</label>
        <select class="form-control" v-model="selectedPlane"
                v-on:change="changePlane()">
          <option :value="null">{{ $t('auto-complete.please-choose') }}</option>
          <option v-for="plane in myPlanes" :key="plane.id" :value="plane">
            {{ plane.registrationNumber }} ({{ plane.model }})
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label>{{ $t('routePlan.planeRegistrationNumber') }}: *</label>
        <input type="text" class="form-control"
               v-model="plan.planeRegistrationNumber"/>
      </div>
      <div class="col-md-6">
        <label>{{ $t('routePlan.defaultSpeed') }}: *</label>
        <div class="input-group">
          <input type="number" class="form-control" v-model="plan.defaultSpeed"/>
          <div class="input-group-append">

            <select class="form-control" v-model="plan.speedUnit">
              <option v-for="unit in speedUnits" :key="unit" :value="unit">
                {{ unit | unitName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label>{{ $t('routePlan.avgConsumption') }}: *</label>
        <div class="input-group">
          <input type="number" class="form-control" v-model="plan.avgConsumption"/>
          <div class="input-group-append">
            <select class="form-control" v-model="plan.avgConsumptionUnit">
              <option v-for="unit in consumptionUnits" :key="unit" :value="unit">
								{{ unit | unitName }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <label>{{ $t('routePlan.pic') }}:</label>
        <div class="input-group">
          <input type="text" class="form-control" v-model="plan.pilotInCommand"/>
        </div>
      </div>
      <div class="col-md-6">
        <label>{{ $t('routePlan.passenger') }}:</label>
        <div class="input-group">
          <input type="text" class="form-control" v-model="plan.passenger"/>
        </div>
      </div>
    </div>

    <div class="row plane-row" style="text-align: center">
      <div class="col-12">
        <div class="wizard-footer-top">
          <button @click="save" class="btn btn-primary">
            <font-awesome-icon icon="check"/>
            {{ $t('planes.save') }}
          </button>
          <button @click="cancel" class="btn btn-secondary ml-3">
            <font-awesome-icon icon="window-close"/>
            {{ $t('planes.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {HELPER_FUNCTIONS} from "@/plugins/utils";


export default {
  name: "RoutePlanForm",
  props: ['plan'],
  emits: ['successSave', 'cancel'],
  data() {
    return {
      myPlanes: [],
      selectedPlane: null,
      speedUnits: Object.keys(this.$units.speed),
      consumptionUnits: Object.keys(this.$units.consumption),
    };
  },
  async mounted() {
    this.plan.dateOfScheduledFlight = HELPER_FUNCTIONS.backEndDateFormat(this.plan.dateOfScheduledFlight)
		const ups = await this.$rest.loadMyUserPlanes() || [];
		this.myPlanes = ups.map(up => up.plane);
		if (this.plan.planeRegistrationNumber && this.plan.planeRegistrationNumber.length > 0) {
			this.selectedPlane = this.myPlanes.find(p => p.registrationNumber === this.plan.planeRegistrationNumber);
			if (!this.selectedPlane) {
				this.changePlane();
			}
		}

  },
  methods: {
    changePlane() {
			this.plan.speedUnit = this.selectedPlane.speedUnit || this.plan.speedUnit;
			this.plan.planeRegistrationNumber = this.selectedPlane.registrationNumber || this.plan.planeRegistrationNumber;
			this.plan.avgConsumptionUnit = this.selectedPlane.consumptionUnit ||  this.plan.avgConsumptionUnit;
			this.plan.avgConsumption = this.$units.convert(Number(this.selectedPlane.defaultConsumption || this.plan.avgConsumption), this.selectedPlane.consumptionUnit, this.plan.avgConsumptionUnit);
			this.plan.defaultSpeed = this.$units.convert(Number(this.selectedPlane.defaultSpeed || this.plan.avgConsumption), this.selectedPlane.speedUnit, this.plan.speedUnit);
    },

    validation() {
      if (this.plan.name.length < 1) {
        alert(this.$t('routePlan.name-empty'));
        return false;
      }
      if (this.plan.planeRegistrationNumber.length < 1) {
        alert(this.$t('routePlan.registrationNumber-empty'));
        return false;
      }
      if (this.plan.defaultSpeed < 1) {
        alert(this.$t('routePlan.speed-empty'));
        return false;
      }
      if (this.plan.avgConsumption < 1) {
        alert(this.$t('routePlan.avg-consuption-empty'));
        return false;
      }
      return true;
    },
    cancel() {
      this.$emit('cancel');
    },
    async save() {
      if (!this.validation()) {
        return;
      }

      this.plan.consumptionUnit = this.$units.getVolumeUnitFromConsumptionUnit(this.plan.avgConsumptionUnit);

			let plan;
			if(this.plan.id) {
				plan = await this.$rest.updateRoutePlan(this.plan);
			} else {
				plan = await this.$rest.addRoutePlan(this.plan);
			}
			if(!plan) return;
			this.$emit('successSave', plan);
    }
  },
}
</script>

<style scoped>
.preview {
  border: 1px solid #e0e0e0;
  padding: 15px;
  margin: 20px;
}
</style>
